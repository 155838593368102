<template>
  <section>
    <div class="dashboard-header">
      <div>
        <h1 class="dashboard-title">
          {{ dashboardTitle }}
        </h1>
        <small class="ml-1">{{ dashboardDescription }}</small>
      </div>
      <button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-tooltip.hover.v-primary
        :title="$t('report_display.refresh')"
        class="btn btn-sm"
        @click="refreshDashboard"
      >
        <i class="fal fa-sync-alt text-primary" />
      </button>
    </div>
    <draggable
      v-model="reportList"
      class="row"
    >
      <common-report
        v-for="(item, index) in reportList"
        :key="item.uid"
        :report-id="item.report_m_id"
        :column-size="item.column"
        :report-index="index"
      />
    </draggable>
  </section>
</template>

<style lang="scss" scoped>
.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.fa-sync-alt {
  font-size: 2rem;
}
</style>

<script>
import { VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import draggable from 'vuedraggable'
import CommonReport from '@/views/Report/components/CommonReport.vue'

import { GET_DASHBOARD } from '@/store/services/dashboard-service'

export default {
  name: 'DashboardDisplay',
  components: {
    draggable,
    CommonReport,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      reportList: [],
      dashboardTitle: '',
      dashboardDescription: '',
    }
  },
  watch: {
    $route() {
      this.refreshDashboard()
    },
  },
  created() {
    this.refreshDashboard()
  },
  methods: {
    getDashboard() {
      this.$store
        .dispatch(GET_DASHBOARD, { id: this.$route.params.id })
        .then(response => {
          if (response.status) {
            response.data.dashboard_detail.forEach(item => {
              this.reportList.push({
                ...item,
                uid: Math.random().toString(16).slice(2),
              })
            })
            this.dashboardTitle = response.data.dashboard_title
            this.dashboardDescription = response.data.description
          }
        })
    },
    refreshDashboard() {
      this.reportList = []
      this.getDashboard()
    },
  },
}
</script>
